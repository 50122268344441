<template>
  <div>
    <div
        class="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top"

    >
      <!--            <h4 class="d-flex flex-center rounded-top height-auto"  style="background: url('')">-->
      <!--                <span class=""> Notifications</span>-->

      <!--            </h4>-->
<!--      <div class="d-flex flex-column pt-6 bgi-size-cover bgi-no-repeat rounded-top"-->
<!--           style="background: url('https://images.unsplash.com/photo-1556660418-fc293a5d848d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');">-->
<!--        <h4 class="d-flex flex-center rounded-top"><span class="text-white">User Notifications</span><span-->
<!--            class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"> {{-->
<!--            notifications.length-->
<!--          }} new </span>-->
<!--        </h4>-->
<!--      </div>-->

      <div class="ml-3 mr-2">

        <perfect-scrollbar
            class="scroll pr-7 mr-n7"
            style="max-height: 40vh; max-width:363px !important; position: relative;"
        >
          <v-skeleton-loader
              v-if="is_loading"
              v-bind="attrs"
              type="list-item-avatar, list-item-avatar, list-item-avatar"
          ></v-skeleton-loader>
          <!--begin::item-->
          <template v-if="!is_loading && notifications && notifications.length">
              <div v-for="(item, i) in notifications" :key="item.id" class="navi navi-hover scroll my-4 ps ps--active-y"
                   style="max-height: 40vh;position: relative;">
                <a @click="openNotificationDetail(item)" target="_blank" class="cursor-pointer navi-item" :class="!item.is_viewed ? 'unread' : ''">
                  <div class="navi-link">
                    <v-avatar tile v-if="item.image">
                      <img
                          :src="item.image_path['real']"
                          alt="user"
                      >
                    </v-avatar>
                    <v-avatar
                        v-else
                        tile
                        color="blue"
                    >
                      <v-icon>
                        mdi-bell
                      </v-icon>
                    </v-avatar>

                    <div class="navi-text ml-2" >
                      <div class="font-weight-bold"
                           v-html="getNotificationTitle(item)"
                      ></div>

                      <div v-if="item.notified_at" class="text-muted">{{ item.notified_at  }}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
          </template>
          <div v-if="!is_loading && notifications.length==0" class="d-flex flex-column align-items-center  p-3">
            <img width="50%" src="@/assets/media/images/no-data-found.png" alt="">
            <h5 class="mt-10">No New Notification.</h5>
          </div>

          <div class="font-weight-bold mb-3 text-right pull-right" style="width: 100px"
               v-if="!is_loading">
            <router-link :to="{ name: 'notification-all'}"

                         class="btn btn-outline-primary text-weight-bold"
            >
              View All
            </router-link>

          </div>
        </perfect-scrollbar>

      </div>

    </div>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import UserNotificationService from "@/services/user/notification/UserNotificationService";
import NoteService from "@/services/note/NoteService";

const notification = new UserNotificationService();
const note = new NoteService();
export default {
  name: "KTDropdownNotification",
  data() {
    return {
      tabIndex: 0,
      is_loading: false,
      attrs: false,
      notifications: [],
      note: {}
    };
  },
  mounted() {
    this.getNotifications()
  },
  methods: {
    getNotifications() {
      notification.getAllNotification({is_not_viewed: 0}, null).then((response) => {
        this.notifications = response.data.data;
      })
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },

    openNotificationDetail(userNotification) {
      if(!userNotification.is_viewed) {
        notification
        .markAsViewed(userNotification.id)
        .then(() => {})
        .catch(() => {});
      }
      if(userNotification.type == 'note') {
          this.$tabs.open({ name: 'reminder-note'})
      } else {
          this.redirectToApplicationDetails(userNotification);
      }

      this.notifications = [];
      this.getNotifications();
      this.$emit('refresh');
    },

    getNotificationTitle(notification) {
      if (notification && notification.notification_title)
        return notification.notification_title
      return notification.title
    },

    redirectToApplicationDetails(userNotification){
      if(userNotification.table_id) {
        if(userNotification.type === 'discussion') {
          this.$tabs.open({
            name: 'application-detail',
            params:{
              id: userNotification.table_id
            },
            query: {
              tabName: 4
            }
          });
        } else if(userNotification.type === 'application_payment') {
          this.$tabs.open({
            name: 'finance'
          });
        } else if(userNotification.type === 'new_agent') {
          this.$tabs.open({
            name: 'user-request-for-agent'
          });
        } else {
          this.$tabs.open({
            name: 'application-detail',
            params:{
              id: userNotification.table_id
            }
          });
        }

       }
    }

  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    }
  }
};
</script>

<style scoped>
.unread {
  background-color: #9e8f8f17;
}
</style>
