<template>
  <ul class="menu-nav">
    <router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <li v-if="checkIsAccessible('appointment', 'list')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/package-types') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-calendar-alt"></i>
        <span class="menu-text">Appointments</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
              :to="{name:'appointments'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-calendar-week"></i>
                <span class="menu-text">Appointments</span>
              </a>
            </li>
          </router-link>
          <router-link
              :to="{name:'my-appointments'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-calendar-day"></i>
                <span class="menu-text">My appointments</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

<!--    <router-link v-if="checkIsAccessible('purpose-for-visit', 'list')"-->
<!--                 to="/purpose-for-visit" v-slot="{ href, navigate, isActive, isExactActive }">-->
<!--      <li-->
<!--          aria-haspopup="true"-->
<!--          data-menu-toggle="hover"-->
<!--          class="menu-item menu-item-submenu"-->
<!--          :class="[-->
<!--                isActive && 'menu-item-active',-->
<!--                isExactActive && 'menu-item-active'-->
<!--              ]"-->
<!--      >-->
<!--        <a :href="href" class="menu-link" @click="navigate">-->
<!--          <i class="menu-icon fas fa-tasks"></i>-->
<!--          <span class="menu-text">Purpose for visit</span>-->
<!--        </a>-->
<!--      </li>-->
<!--    </router-link>-->
      <router-link v-if="checkIsAccessible('address-book', 'list')"
                   :to="{ name: 'address-book' }"
                   v-slot="{ href, navigate, isActive, isExactActive }"
      >
          <li
                  class="menu-item"
                  :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
          >
              <a :href="href" class="menu-link">
                  <i class="menu-icon fas fa-address-book"></i>
                  <span class="menu-text">Address book</span>
              </a>
          </li>
      </router-link>
    <li v-if="checkIsAccessible('contact-us', 'list')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/package-types') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-envelope"></i>
        <span class="menu-text">Lead & Inquiries</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">

          <router-link
              v-if="checkIsAccessible('enquiry', 'list')"
              :to="{ name: 'enquiry' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-question-circle"></i>
                <span class="menu-text">Inquiries</span>
              </a>
            </li>
          </router-link>
          <router-link
              v-if="checkIsAccessible('contact-us', 'list') && mySiteSetting && mySiteSetting.has_website"
              :to="{ name: 'messages' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon flaticon2-mail-1"></i>
                <span class="menu-text">Web Inquiries <span class="badge badge-square badge-primary ml-5">{{$store.getters.getOnlineInquiry}}</span></span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>


    <li v-if="checkIsAccessible('application', 'list')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/application') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-graduation-cap"></i>
        <span class="menu-text">Academic applications</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">

          <router-link
              :to="{ name: 'applications',  params:{type:'academic', status: 'draft'} }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
<!--                <i class="menu-icon fas fa-circle"></i>-->
                <span class="menu-text">Drafts</span>
                <span class="badge badge-info font-size-sm mt-2 h-25">{{ `${academic_application_draft_count}` }}</span>

              </a>
            </li>
          </router-link>

          <router-link
              :to="{ name: 'applications',  params:{type:'academic', status: 'submitted'} }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
<!--                <i class="menu-icon fas fa-question-circle"></i>-->
                <span class="menu-text">Submitted</span>
                <span class="badge badge-info font-size-sm mt-2 h-25">{{ `${academic_application_submited_count}` }}</span>

              </a>
            </li>
          </router-link>

          <router-link
              :to="{ name: 'applications',  params:{type:'academic', status: 'valid-offer'} }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
<!--                <i class="menu-icon fas fa-question-circle"></i>-->
                <span class="menu-text">Validated for offer </span>
                <span class="badge badge-info font-size-sm mt-2 h-25">{{ `${academic_application_valid_offers_count}` }}</span>

              </a>
            </li>
          </router-link>

          <router-link
              :to="{ name: 'applications',  params:{type:'academic', status: 'offer'} }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
<!--                <i class="menu-icon fas fa-question-circle"></i>-->
                <span class="menu-text">Offerred</span>
                <span class="badge badge-info font-size-sm mt-2 h-25">{{ `${academic_application_offers_count}` }}</span>

              </a>
            </li>
          </router-link>

          <router-link
              :to="{ name: 'applications',  params:{type:'academic', status: 'visa-ready'} }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <!--                <i class="menu-icon flaticon2-mail-1"></i>-->
                <span class="menu-text">Visa pending</span>
                <span class="badge badge-info font-size-sm mt-2 h-25">{{ `${academic_application_visa_ready_count}` }}</span>
              </a>
            </li>
          </router-link>

          <router-link
              :to="{ name: 'applications',  params:{type:'academic', status: 'deferred'} }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
<!--                <i class="menu-icon fas fa-question-circle"></i>-->
                <span class="menu-text">Deferral</span>
                <span class="badge badge-info font-size-sm mt-2 h-25">{{ `${academic_application_deferred_count}` }}</span>

              </a>
            </li>
          </router-link>

          <router-link
              :to="{ name: 'applications',  params:{type:'academic', status: 'refund'} }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
<!--                <i class="menu-icon fas fa-question-circle"></i>-->
                <span class="menu-text">Refund</span>
                <span class="badge badge-info font-size-sm mt-2 h-25">{{ `${academic_application_refund_count}` }}</span>

              </a>
            </li>
          </router-link>



          <router-link
              :to="{ name: 'applications',  params:{type:'academic', status: 'visa-granted'} }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
<!--                <i class="menu-icon flaticon2-mail-1"></i>-->
                <span class="menu-text">Visa granted</span>
                <span class="badge badge-info font-size-sm mt-2 h-25">{{ `${academic_application_visa_granted_count}` }}</span>
              </a>
            </li>
          </router-link>
          <router-link
                  :to="{ name: 'applications',  params:{type:'academic', status: 'archived'} }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
          >
              <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item menu-item-submenu"
                      :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
              >
                  <a :href="href" class="menu-link">
                      <!--                <i class="menu-icon flaticon2-mail-1"></i>-->
                      <span class="menu-text">Archived</span>
                      <span class="badge badge-info font-size-sm mt-2 h-25">{{ `${academic_application_archived_count}` }}</span>
                  </a>
              </li>
          </router-link>
        </ul>
      </div>
    </li>

<!--    <router-link v-if="checkIsAccessible('application', 'list')"-->
<!--                 :to="{ name: 'application', params:{'type':'visa'} }"-->
<!--                 v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--    >-->
<!--      <li-->
<!--          aria-haspopup="true"-->
<!--          data-menu-toggle="hover"-->
<!--          class="menu-item menu-item-submenu"-->
<!--          :class="[-->
<!--                isActive && 'menu-item-active',-->
<!--                isExactActive && 'menu-item-active'-->
<!--              ]"-->
<!--      >-->
<!--        <a :href="href" class="menu-link">-->
<!--          <i class="menu-icon fas fa-globe"></i>-->
<!--          <span class="menu-text">Visa Applications </span>-->
<!--          <span class="badge badge-danger font-size-sm font-weight-boldest">{{ `${visa_application_count}` }}</span>-->
<!--        </a>-->
<!--      </li>-->
<!--    </router-link>-->

    <router-link v-if="checkIsAccessible('task-manager', 'list')"
                 to="/task-manager" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-tasks"></i>
          <span class="menu-text">Task manager</span>
        </a>
      </li>
    </router-link>

    <router-link v-if="checkIsAccessible('finance', 'list')"
                 to="/finance" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon mdi mdi-finance"></i>
          <span class="menu-text">Finance <span class="badge badge-square badge-primary ml-5">{{academic_application_pending_payments_count}}</span></span>
        </a>
      </li>
    </router-link>

    <li v-if="checkIsAccessible('application-discussion', 'list')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/package-types') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-comment-alt"></i>
        <span class="menu-text">Discussions</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu" >
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">

            <router-link
                    :to="{name:'application-discussion', params: {category_slug: category.slug}}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-for="(category, index) in categories" :key="index"
            >
                <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item menu-item-submenu"
                        :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                >
                    <a :href="href" class="menu-link d-flex">
                      <span class="menu-text">{{category.name}}<span
                              class="badge badge-square badge-primary ml-5">{{ category.discussion_count }}</span></span>
                    </a>
                </li>
            </router-link>

        </ul>
      </div>
    </li>
      <router-link v-if="checkIsAccessible('resource-file', 'list')"
                   :to="{ name: 'resource-folder' }"
                   v-slot="{ href, navigate, isActive, isExactActive }"
      >
          <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item menu-item-submenu"
                  :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
          >
              <a :href="href" class="menu-link">
                  <i class="menu-icon fas fa-folder"></i>
                  <span class="menu-text">File & Resources</span>
              </a>
          </li>
      </router-link>
      <router-link v-if="checkIsAccessible('leave-request', 'list')"
                   :to="{ name: 'leave', params: { type: 'admin' } }"
                   v-slot="{ href, navigate, isActive, isExactActive }"
      >
          <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item menu-item-submenu"
                  :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
          >
              <a :href="href" class="menu-link">
                  <i class="menu-icon fas fa-umbrella-beach"></i>
                  <span class="menu-text">Leave requests</span>
              </a>
          </li>
      </router-link>

  </ul>
</template>
<script>
import UserPaymentService from "@/services/user/payment/UserPaymentService";

const paymentService = new UserPaymentService();
export default {
  name: "KTMenu",

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    getUnReadDiscussion() {
      this.$store.dispatch('GET_DISCUSSION_CATEGORIES');
    },
  },

  mounted() {
    this.getUnReadDiscussion();
  },

  computed: {
    totalCategoryCount() {
      return this.$store.getters.totalCategory;
    },
    categories() {
      return this.$store.getters.getCategories;
    },
    isAdmin() {
      return this.currentUser.type === "admin" ? true : false;
    },
    academic_application_open_count() {
      return this.$store.getters.getAcademicApplicationOpen;
    },
    academic_application_not_open_count() {
      return this.$store.getters.getAcademicApplicationNotOpen;
    },
    academic_application_draft_count() {
      return this.$store.getters.getAcademicApplicationDraft;
    },
    academic_application_submited_count() {
      return this.$store.getters.getAcademicApplicationSubmit;
    },
    academic_application_valid_offers_count() {
      return this.$store.getters.getAcademicApplicationValidOffers;
    },
    academic_application_offers_count() {
      return this.$store.getters.getAcademicApplicationOffers;
    },
    academic_application_deferred_count() {
      return this.$store.getters.getAcademicApplicationDeferred;
    },
    academic_application_refund_count() {
      return this.$store.getters.getAcademicApplicationRefund;
    },
    academic_application_archived_count() {
      return this.$store.getters.getAcademicApplicationArchived;
    },
    academic_application_visa_ready_count() {
      return this.$store.getters.getAcademicApplicationVisaReady;
    },
    academic_application_visa_granted_count() {
      return this.$store.getters.getAcademicApplicationVisaGranted;
    },
    visa_application_count() {
      return this.$store.getters.getVisaApplicationCount;
    },
    academic_application_pending_payments_count() {
      return this.$store.getters.getAcademicApplicationPendingPayments;
    },
  },
};
</script>
