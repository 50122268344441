<template>
    <!-- begin:: Header Topbar -->
    <div class="topbar">
        <!--begin: Search -->
        <!--    <b-dropdown-->
        <!--      size="sm"-->
        <!--      id="kt_quick_search_toggle"-->
        <!--      variant="link"-->
        <!--      toggle-academic-class="topbar-item text-decoration-none"-->
        <!--      no-caret-->
        <!--      right-->
        <!--      no-flip-->
        <!--    >-->
        <!--      <template v-slot:button-content>-->
        <!--        <div academic-class="btn btn-icon  btn-lg btn-dropdown mr-1">-->
        <!--          <span academic-class="svg-icon svg-icon-xl svg-icon-primary">-->
        <!--            <inline-svg src="media/svg/icons/General/Search.svg" />-->
        <!--          </span>-->
        <!--        </div>-->
        <!--      </template>-->
        <!--      <b-dropdown-text tag="div" academic-class="min-w-md-350px">-->
        <!--        <KTSearchDefault></KTSearchDefault>-->
        <!--      </b-dropdown-text>-->
        <!--    </b-dropdown>-->
        <!--end: Search -->
        <!--begin: Notifications -->
        <b-dropdown
                size="sm"
                variant="link"
                toggle-class="topbar-item text-decoration-none"
                no-caret
                right
                no-flip
        >
            <template v-slot:button-content >
                <div
                        @click="callNotification"
                        class="btn btn-icon  btn-dropdown btn-lg mr-1 pulse pulse-primary"
                >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
                <i class="menu-icon far fa-bell"><span></span></i>
          </span>
<!--                    <span class="pulse-ring"></span>-->
                  <span v-if="notificationsCount > 0" class="badge badge-primary rounded-pill badge-sm px-2 py-1 mx-1">{{notificationsCount}}</span>

                </div>
            </template>
            <b-dropdown-text tag="div" class="min-w-md-350px">
                <form>
                    <KTDropdownNotification ref="notification" @refresh="initialNotificationCount"></KTDropdownNotification>
                </form>
            </b-dropdown-text>
        </b-dropdown>
        <!--end: Notifications -->

<!--        &lt;!&ndash;begin: My Cart &ndash;&gt;-->
<!--        <router-link-->
<!--                to="/cart"-->
<!--                size="sm"-->
<!--                variant="link"-->
<!--                toggle-academic-class="topbar-item text-decoration-none"-->
<!--                no-caret-->
<!--                right-->
<!--                no-flip-->
<!--        >-->
<!--            &lt;!&ndash;      <template v-slot:button-content>&ndash;&gt;-->
<!--            <div academic-class="btn btn-icon  btn-dropdown btn-lg mr-1">-->
<!--          <span academic-class="svg-icon svg-icon-xl svg-icon-primary">-->
<!--               <i academic-class="menu-icon fas fa-shopping-cart"><span></span></i>-->

<!--          </span>-->
<!--            </div>-->
<!--            &lt;!&ndash;      </template>&ndash;&gt;-->
<!--            &lt;!&ndash;      <b-dropdown-text tag="div" academic-class="min-w-md-350px">&ndash;&gt;-->
<!--            &lt;!&ndash;        <KTDropdownMyCart></KTDropdownMyCart>&ndash;&gt;-->
<!--            &lt;!&ndash;      </b-dropdown-text>&ndash;&gt;-->
<!--        </router-link>-->
        <!--end: My Cart -->

        <!--begin: User Bar -->
        <KTQuickUser></KTQuickUser>
        <!--end: User Bar -->
    </div>
    <!-- end:: Header Topbar -->
</template>

<style lang="scss">
    .topbar {
        .dropdown-toggle {
            padding: 0;

            &:hover {
                text-decoration: none;
            }

            &.dropdown-toggle-no-caret {
                &:after {
                    content: none;
                }
            }
        }

        .dropdown-menu {
            margin: 0;
            padding: 0;
            outline: none;

            .b-dropdown-text {
                padding: 0;
            }
        }
    }
</style>

<script>
    import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
    import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
    import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
    import i18nService from "@/core/services/i18n.service.js";
    import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
    import UserNotificationService from "@/services/user/notification/UserNotificationService";

    const userNotification = new UserNotificationService();
    export default {
        name: "KTTopbar",
        data() {
            return {
                languageFlag: "",
                languages: i18nService.languages,
                notificationsCount: 0
            };
        },
        components: {
            KTDropdownNotification,
            KTSearchDefault,
            KTDropdownMyCart,
            KTQuickUser,
        },
        mounted() {
          this.initialNotificationCount()
          setInterval(() => {
              this.initialNotificationCount();
          }, 100000);
        },
        methods: {
            initialNotificationCount(){
              userNotification
                  .getAdminNotificationsCount()
                  .then((response) => {
                    this.notificationsCount = response.data.count;
                  })
                  .catch((err) => {

                  });
            },
            callNotification() {
              this.initialNotificationCount();
              this.$refs['notification'].getNotifications();
            },
            onLanguageChanged() {
                this.languageFlag = this.languages.find(val => {
                    return val.lang === i18nService.getActiveLanguage();
                }).flag;
            }
        },
        computed: {
            getLanguageFlag() {
                return this.onLanguageChanged();
            },
            user() {
                return this.$store.getters.currentUser;
            }
        }
    };
</script>
