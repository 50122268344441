<template>
  <ul class="menu-nav">


    <!--    User-->
    <li v-if="checkIsAccessible('other-menu', 'agent')"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Agents</span>
      </a>

      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav" style="max-width: 1000px">
          <ul class="menu-content">
            <li class="menu-item" v-if="checkIsAccessible('agent', 'list')">
              <ul class="menu-inner">
                <router-link v-if="checkIsAccessible('agent', 'list')"
                             :to="{ name: 'user-agent' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-user-tie"
                      ><span></span
                      ></i>
                      <span class="menu-text">Agents</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('agent', 'list')"
                             :to="{ name: 'agent-received' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fa fa-file"
                      ><span></span
                      ></i>
                      <span class="menu-text">Incomplete documentations </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('agent', 'withdrawn-manage')"
                             :to="{ name: 'user-request-for-agent' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-clipboard-question"
                      ><span></span
                      ></i>
                      <span class="menu-text">Pending approval</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('agent', 'withdrawn-application')"
                             :to="{ name: 'application-withdraw' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-cancel"
                      ><span></span
                      ></i>
                      <span class="menu-text">Withdrawn</span>
                    </a>
                  </li>
                </router-link>


              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <li v-if="checkIsAccessible('other-menu', 'student')"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Candidates</span>
      </a>

      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav" style="max-width: 1000px">
          <ul class="menu-content">
            <li class="menu-item" v-if="checkIsAccessible('user', 'list')">
              <ul class="menu-inner">
                <router-link v-if="checkIsAccessible('user', 'list')"
                             :to="{ name: 'user-student' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-user-lock"
                      ><span></span
                      ></i>
                      <span class="menu-text">Candidates referred by agent</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('user', 'list')"
                               :to="{ name: 'direct-student' }"
                               v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                      <li
                              aria-haspopup="true"
                              class="menu-item"
                              :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                      >
                          <a :href="href" class="menu-link" @click="navigate">
                              <i class="menu-icon fas fa-user-tie"
                              ><span></span
                              ></i>
                              <span class="menu-text">All candidates</span>
                          </a>
                      </li>
                  </router-link>

                <router-link v-if="checkIsAccessible('user', 'list')"
                             :to="{ name: 'future-lead' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-users"
                      ><span></span
                      ></i>
                      <span class="menu-text">Onshore Candidate</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <li v-if="checkIsAccessible('other-menu', 'education-program')"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Education programs </span>
      </a>

      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav" style="max-width: 1000px">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">


                <router-link v-if="checkIsAccessible('academic-program', 'list')"
                             :to="{ name: 'program' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-user-graduate"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Programs</span>
                    </a>
                  </li>
                </router-link>
                <router-link v-if="checkIsAccessible('institution', 'list')"
                             :to="{ name: 'institutions' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-school"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Institutions</span>
                    </a>
                  </li>
                </router-link>
                <router-link v-if="checkIsAccessible('academic-program-session', 'list')"
                             :to="{ name: 'academic-program-session' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-business-time"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Academic sessions</span>
                    </a>
                  </li>
                </router-link>
                <router-link v-if="checkIsAccessible('grading-scheme', 'list')"
                             :to="{ name: 'grading-scheme'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-bars"></i>
                      <span class="menu-text">Grading scheme</span>
                    </a>
                  </li>
                </router-link>
                <router-link v-if="checkIsAccessible('education-level', 'list')"
                             :to="{ name: 'education-level'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-layer-group"></i>
                      <span class="menu-text">Education levels</span>
                    </a>
                  </li>
                </router-link>
                <router-link v-if="checkIsAccessible('institution-type', 'list')"
                             :to="{ name: 'institution-type' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-stream"></i>
                      <span class="menu-text">Institution types</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('academic-program-category', 'list')"
                             :to="{ name: 'academic-program-category' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-cogs"></i>
                      <span class="menu-text">Category(Field)</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <!--    finance-->
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
        v-if="checkIsAccessible('finance', 'list') || checkIsAccessible('finance-history-type', 'list') || checkIsAccessible('financial-transaction-head', 'list') || checkIsAccessible('financial-payment-type', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Finance</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav " style="max-width: 1000px;">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link v-if="checkIsAccessible('finance', 'list')"
                             :to="{ name: 'invoice' }" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-file-invoice"><span></span></i>
                      <span class="menu-text"> Invoice </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('finance', 'list')"
                             :to="{ name: 'finance-history' }" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-money-check"><span></span></i>
                      <span class="menu-text"> Transactions </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('financial-transaction-head', 'list')"
                             :to="{ name: 'finance-transaction-head' }"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-file-invoice-dollar"><span></span></i>
                      <span class="menu-text"> Transaction headings </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('financial-payment-type', 'list')"
                             :to="{ name: 'payment-type' }"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-file-invoice-dollar"><span></span></i>
                      <span class="menu-text"> Invoice particulars</span>
                    </a>
                  </li>
                </router-link>

              </ul>
            </li>

          </ul>
        </div>
      </div>
    </li>
    <!--    finance-->

    <!--    CMS-->
    <li v-if="checkIsAccessible('other-menu', 'cms') && mySiteSetting && mySiteSetting.has_website"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> CMS</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav" style="max-width: 1000px">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">

                <router-link
                    :to="{ name: 'faq' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-question-circle"></i>
                      <span class="menu-text">FAQs</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'download' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-download"></i>
                      <span class="menu-text">Downloads</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'slider' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-sliders"></i>
                      <span class="menu-text">Slider</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'menu-manager' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-bars"></i>
                      <span class="menu-text">Menu</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'blog' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-blog"></i>
                      <span class="menu-text">Blog</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'email-response' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-commenting"></i>
                      <span class="menu-text">Predefined responses</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
            <li class="menu-item">
              <ul class="menu-inner">


                <router-link
                    :to="{ name: 'page' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon flaticon2-copy"></i>
                      <span class="menu-text">Pages</span>
                    </a>
                  </li>
                </router-link>
                <router-link

                    :to="{name:'testimonial'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"

                      class="menu-item "
                      :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-people-arrows"></i>
                      <span class="menu-text">Testimonial</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'notice' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-bullhorn"></i>
                      <span class="menu-text">Notices</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'popup' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-external-link-alt"></i>
                      <span class="menu-text">Popups</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'media' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-images"></i>
                      <span class="menu-text">Media</span>
                    </a>
                  </li>
                </router-link>


              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>

      <li v-if="checkIsAccessible('other-menu', 'visa-setting') && currentUser && currentUser.access_type === 'head'"
          aria-haspopup="true"
          data-menu-toggle="click"
          class="menu-item menu-item-submenu menu-item-open-dropdown"
          v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
      >
          <a href="#" class="menu-link menu-toggle">
              <span class="menu-text"> Visa settings </span>
          </a>

          <div class="menu-submenu menu-submenu-fixed">
              <div class="menu-subnav" style="max-width: 1000px">
                  <ul class="menu-content">
                      <li class="menu-item">
                          <ul class="menu-inner">
                              <router-link v-if="checkIsAccessible('visa-type', 'list')"
                                           :to="{ name: 'visa-type' }"
                                           v-slot="{ href, navigate, isActive, isExactActive }"
                              >
                                  <li class="menu-item" :class="[
                                    isActive && 'menu-item-active',
                                    isExactActive && 'menu-item-active',
                                  ]"
                                  >
                                      <a :href="href" class="menu-link">
                                          <i class="menu-icon fas fa-globe-asia"></i>
                                          <span class="menu-text">Visa type</span>
                                      </a>
                                  </li>
                              </router-link>
                              <router-link v-if="checkIsAccessible('skill-occupation', 'list')"
                                           :to="{ name: 'skill-occupation' }"
                                           v-slot="{ href, navigate, isActive, isExactActive }"
                              >
                                  <li class="menu-item" :class="[
                                      isActive && 'menu-item-active',
                                      isExactActive && 'menu-item-active',
                                    ]"
                                  >
                                      <a :href="href" class="menu-link">
                                          <i class="menu-icon fas fa-chalkboard-teacher"></i>
                                          <span class="menu-text">Skill occupations</span>
                                      </a>
                                  </li>
                              </router-link>

                              <router-link v-if="checkIsAccessible('nomination-sub-class', 'list')"
                                           :to="{ name: 'nomination-subclass' }"
                                           v-slot="{ href, navigate, isActive, isExactActive }"
                              >
                                  <li class="menu-item" :class="[
                                    isActive && 'menu-item-active',
                                    isExactActive && 'menu-item-active',
                                  ]"
                                  >
                                      <a :href="href" class="menu-link">
                                          <i class="menu-icon fas fa-border-style"></i>
                                          <span class="menu-text">Nomination subclass</span>
                                      </a>
                                  </li>
                              </router-link>

                              <router-link v-if="checkIsAccessible('nomination-category', 'list')"
                                           :to="{ name: 'nomination-category' }"
                                           v-slot="{ href, navigate, isActive, isExactActive }"
                              >
                                  <li
                                          class="menu-item"
                                          :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                                  >
                                      <a :href="href" class="menu-link">
                                          <i class="menu-icon fas fa-list-alt"></i>
                                          <span class="menu-text">Nomination category</span>
                                      </a>
                                  </li>
                              </router-link>

                              <router-link v-if="checkIsAccessible('required-document', 'list')"
                                           :to="{ name: 'required-document' }"
                                           v-slot="{ href, navigate, isActive, isExactActive }"
                              >
                                  <li class="menu-item" :class="[
                                    isActive && 'menu-item-active',
                                    isExactActive && 'menu-item-active',
                                  ]"
                                  >
                                      <a :href="href" class="menu-link">
                                          <i class="menu-icon fas fa-file"></i>
                                          <span class="menu-text">Document requirements</span>
                                      </a>
                                  </li>
                              </router-link>

                              <router-link v-if="checkIsAccessible('application-type', 'list')"
                                           :to="{ name: 'application-type' }"
                                           v-slot="{ href, navigate, isActive, isExactActive }"
                              >
                                  <li class="menu-item" :class="[
                                        isActive && 'menu-item-active',
                                        isExactActive && 'menu-item-active',
                                      ]"
                                  >
                                      <a :href="href" class="menu-link">
                                          <i class="menu-icon fas fa-user-secret"></i>
                                          <span class="menu-text">Application type</span>
                                      </a>
                                  </li>
                              </router-link>

                              <router-link v-if="checkIsAccessible('visa-application-status', 'list')"
                                           :to="{name:'visa-application-status'}"
                                           v-slot="{ href, navigate, isActive, isExactActive }"
                              >
                                  <li
                                          aria-haspopup="true"
                                          data-menu-toggle="hover"
                                          class="menu-item menu-item-submenu"
                                          :class="[
                                        isActive && 'menu-item-active',
                                        isExactActive && 'menu-item-active'
                                      ]"
                                  >
                                      <a :href="href" class="menu-link">
                                          <i class="menu-icon fas fa-school"></i>
                                          <span class="menu-text">Visa Status manager</span>
                                      </a>
                                  </li>
                              </router-link>

                          </ul>
                      </li>
                  </ul>
              </div>
          </div>
      </li>
      <li
        v-if="checkIsAccessible('other-menu', 'setting')"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> System settings </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <ul class="menu-subnav">
          <router-link v-if="checkIsAccessible('site-setting', 'list')"
                       :to="{ name: 'setting' }"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item"
                :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-globe"></i>
                <span class="menu-text">Site settings</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('country', 'list')"
                       :to="{ name: 'countries' }"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item"
                :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-globe-asia"></i>
                <span class="menu-text">Countries</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('leave-category', 'list')"
                       :to="{ name: 'leave-category', params: { type: 'admin' } }"
                       v-slot="{ href, navigate, isActive, isExactActive }"

          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                                                isActive && 'menu-item-active',
                                                isExactActive && 'menu-item-active'
                                              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-umbrella-beach"><span></span></i>
                <span class="menu-text"> Leave categories </span>
              </a>
            </li>
          </router-link>
          <router-link
              :to="{name:'category'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="checkIsAccessible('discussion-category', 'list')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-comment-alt"></i>
                <span class="menu-text">Discussion category</span>
              </a>
            </li>
          </router-link>
          <router-link v-if="checkIsAccessible('branch', 'list')"
                       :to="{ name: 'branch' }"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item"
                :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-map-marker-alt"></i>
                <span class="menu-text">Manage branch</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('email-template', 'list')"
                       :to="{ name: 'email-template-roles' }"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item"
                :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-envelope"></i>
                <span class="menu-text">Email templates</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('service', 'list')"
                       :to="{name:'service'}"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-cogs"></i>
                <span class="menu-text"> Transaction headings</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('subscription', 'list')"
                       :to="{ name: 'subscription' }"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item"
                :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-dollar-sign"></i>
                <span class="menu-text">Agent subscription plans</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('attribute', 'list')"
                       :to="{ name: 'attributes' }"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item"
                :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-users-cog"></i>
                <span class="menu-text">Attributes</span>
              </a>
            </li>
          </router-link>

          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
              'menu-item-active': hasActiveChildren('/custom-pages')
            }"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-icon fas fa-user-shield"></i>
              <span class="menu-text">User Roles & Permission</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
              <ul class="menu-subnav">
                <router-link
                    v-if="checkIsAccessible('admin-user', 'list')"
                    :to="{name:'admin-user'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item menu-item-submenu"
                      :class="[
                                                isActive && 'menu-item-active',
                                                isExactActive && 'menu-item-active'
                                              ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-users"></i>
                      <span class="menu-text">Users</span>
                    </a>
                  </li>
                </router-link>
                <router-link v-if="checkIsAccessible('role', 'list')"
                             :to="{name:'role'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item menu-item-submenu"
                      :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-user-lock"></i>
                      <span class="menu-text">Role</span>
                    </a>
                  </li>
                </router-link>

              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
    <!--    CMS-->
    <li
        v-if="checkIsAccessible('other-menu', 'alert')"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Alerts <span class="badge badge-square badge-primary ml-5">{{totalAlert}}</span></span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    :to="{ name: 'application-deferred-update-requests'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-calendar-minus"></i>
                      <span class="menu-text">Application deferred<span class="badge badge-square badge-primary ml-5">{{$store.getters.getApplicationDeferred}}</span></span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'application-refund-update-requests'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-money-bill-transfer"></i>
                      <span class="menu-text">Application refund<span class="badge badge-square badge-primary ml-5">{{$store.getters.getApplicationRefund}}</span></span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'change-request'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-user-edit"></i>
                      <span class="menu-text">Agent information update request<span class="badge badge-square badge-primary ml-5">{{$store.getters.getAgentChangeRequest}}</span></span>
                    </a>
                  </li>
                </router-link>
                  <router-link v-if="checkIsAccessible('note', 'list')"
                               :to="{name:'reminder-note'}"
                               v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                      <li
                              aria-haspopup="true"
                              data-menu-toggle="hover"
                              class="menu-item menu-item-submenu"
                              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                      >
                          <a :href="href" class="menu-link">
                              <i class="menu-icon mdi mdi-note"></i>
                              <span class="menu-text">Note reminder<span class="badge badge-square badge-primary ml-5">{{$store.getters.getNotes}}</span></span>
                          </a>
                      </li>
                  </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>

export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  computed: {

    isAdmin() {
      return this.currentUser.type === "admin" ? true : false;
    },
    totalAlert() {
      return this.$store.getters.getApplicationDeferred + this.$store.getters.getApplicationRefund + this.$store.getters.getAgentChangeRequest;
    }
  },
};
</script>
