var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),(_vm.checkIsAccessible('appointment', 'list'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/package-types') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":{name:'appointments'}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-calendar-week"}),_c('span',{staticClass:"menu-text"},[_vm._v("Appointments")])])])]}}],null,false,1076098835)}),_c('router-link',{attrs:{"to":{name:'my-appointments'}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-calendar-day"}),_c('span',{staticClass:"menu-text"},[_vm._v("My appointments")])])])]}}],null,false,2389607655)})],1)])]):_vm._e(),(_vm.checkIsAccessible('address-book', 'list'))?_c('router-link',{attrs:{"to":{ name: 'address-book' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-address-book"}),_c('span',{staticClass:"menu-text"},[_vm._v("Address book")])])])]}}],null,false,4061309863)}):_vm._e(),(_vm.checkIsAccessible('contact-us', 'list'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/package-types') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.checkIsAccessible('enquiry', 'list'))?_c('router-link',{attrs:{"to":{ name: 'enquiry' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-question-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v("Inquiries")])])])]}}],null,false,482281434)}):_vm._e(),(_vm.checkIsAccessible('contact-us', 'list') && _vm.mySiteSetting && _vm.mySiteSetting.has_website)?_c('router-link',{attrs:{"to":{ name: 'messages' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon flaticon2-mail-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Web Inquiries "),_c('span',{staticClass:"badge badge-square badge-primary ml-5"},[_vm._v(_vm._s(_vm.$store.getters.getOnlineInquiry))])])])])]}}],null,false,2074211003)}):_vm._e()],1)])]):_vm._e(),(_vm.checkIsAccessible('application', 'list'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/application') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(2),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":{ name: 'applications',  params:{type:'academic', status: 'draft'} }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v("Drafts")]),_c('span',{staticClass:"badge badge-info font-size-sm mt-2 h-25"},[_vm._v(_vm._s(`${_vm.academic_application_draft_count}`))])])])]}}],null,false,3483256304)}),_c('router-link',{attrs:{"to":{ name: 'applications',  params:{type:'academic', status: 'submitted'} }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v("Submitted")]),_c('span',{staticClass:"badge badge-info font-size-sm mt-2 h-25"},[_vm._v(_vm._s(`${_vm.academic_application_submited_count}`))])])])]}}],null,false,1786782807)}),_c('router-link',{attrs:{"to":{ name: 'applications',  params:{type:'academic', status: 'valid-offer'} }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v("Validated for offer ")]),_c('span',{staticClass:"badge badge-info font-size-sm mt-2 h-25"},[_vm._v(_vm._s(`${_vm.academic_application_valid_offers_count}`))])])])]}}],null,false,900226176)}),_c('router-link',{attrs:{"to":{ name: 'applications',  params:{type:'academic', status: 'offer'} }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v("Offerred")]),_c('span',{staticClass:"badge badge-info font-size-sm mt-2 h-25"},[_vm._v(_vm._s(`${_vm.academic_application_offers_count}`))])])])]}}],null,false,2169609507)}),_c('router-link',{attrs:{"to":{ name: 'applications',  params:{type:'academic', status: 'visa-ready'} }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v("Visa pending")]),_c('span',{staticClass:"badge badge-info font-size-sm mt-2 h-25"},[_vm._v(_vm._s(`${_vm.academic_application_visa_ready_count}`))])])])]}}],null,false,3130178088)}),_c('router-link',{attrs:{"to":{ name: 'applications',  params:{type:'academic', status: 'deferred'} }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v("Deferral")]),_c('span',{staticClass:"badge badge-info font-size-sm mt-2 h-25"},[_vm._v(_vm._s(`${_vm.academic_application_deferred_count}`))])])])]}}],null,false,3552172751)}),_c('router-link',{attrs:{"to":{ name: 'applications',  params:{type:'academic', status: 'refund'} }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v("Refund")]),_c('span',{staticClass:"badge badge-info font-size-sm mt-2 h-25"},[_vm._v(_vm._s(`${_vm.academic_application_refund_count}`))])])])]}}],null,false,1484976451)}),_c('router-link',{attrs:{"to":{ name: 'applications',  params:{type:'academic', status: 'visa-granted'} }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v("Visa granted")]),_c('span',{staticClass:"badge badge-info font-size-sm mt-2 h-25"},[_vm._v(_vm._s(`${_vm.academic_application_visa_granted_count}`))])])])]}}],null,false,3297897340)}),_c('router-link',{attrs:{"to":{ name: 'applications',  params:{type:'academic', status: 'archived'} }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v("Archived")]),_c('span',{staticClass:"badge badge-info font-size-sm mt-2 h-25"},[_vm._v(_vm._s(`${_vm.academic_application_archived_count}`))])])])]}}],null,false,2363710531)})],1)])]):_vm._e(),(_vm.checkIsAccessible('task-manager', 'list'))?_c('router-link',{attrs:{"to":"/task-manager"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-tasks"}),_c('span',{staticClass:"menu-text"},[_vm._v("Task manager")])])])]}}],null,false,2652879118)}):_vm._e(),(_vm.checkIsAccessible('finance', 'list'))?_c('router-link',{attrs:{"to":"/finance"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-finance"}),_c('span',{staticClass:"menu-text"},[_vm._v("Finance "),_c('span',{staticClass:"badge badge-square badge-primary ml-5"},[_vm._v(_vm._s(_vm.academic_application_pending_payments_count))])])])])]}}],null,false,1794179506)}):_vm._e(),(_vm.checkIsAccessible('application-discussion', 'list'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/package-types') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(3),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},_vm._l((_vm.categories),function(category,index){return _c('router-link',{key:index,attrs:{"to":{name:'application-discussion', params: {category_slug: category.slug}}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link d-flex",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(category.name)),_c('span',{staticClass:"badge badge-square badge-primary ml-5"},[_vm._v(_vm._s(category.discussion_count))])])])])]}}],null,true)})}),1)])]):_vm._e(),(_vm.checkIsAccessible('resource-file', 'list'))?_c('router-link',{attrs:{"to":{ name: 'resource-folder' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-folder"}),_c('span',{staticClass:"menu-text"},[_vm._v("File & Resources")])])])]}}],null,false,2675131299)}):_vm._e(),(_vm.checkIsAccessible('leave-request', 'list'))?_c('router-link',{attrs:{"to":{ name: 'leave', params: { type: 'admin' } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-umbrella-beach"}),_c('span',{staticClass:"menu-text"},[_vm._v("Leave requests")])])])]}}],null,false,3915923379)}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fas fa-calendar-alt"}),_c('span',{staticClass:"menu-text"},[_vm._v("Appointments")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fas fa-envelope"}),_c('span',{staticClass:"menu-text"},[_vm._v("Lead & Inquiries")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fas fa-graduation-cap"}),_c('span',{staticClass:"menu-text"},[_vm._v("Academic applications")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fas fa-comment-alt"}),_c('span',{staticClass:"menu-text"},[_vm._v("Discussions")]),_c('i',{staticClass:"menu-arrow"})])
}]

export { render, staticRenderFns }